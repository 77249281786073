@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
}

.title-gray {
  @apply text-gray block w-full m-0 p-0 mb-3 last:mb-0;
}
.title-gray h2 {
  @apply text-2xl font-semibold;
}
.title-gray h3 {
  @apply text-xl font-semibold;
}
.title-gray h4 {
  @apply text-lg font-semibold;
}
.title-gray h5 {
  @apply text-base font-semibold;
}
.title-gray h6 {
  @apply text-sm font-semibold;
}

.title-primary {
  @apply text-primary block w-full m-0 p-0 mb-3 last:mb-0;
}
.title-primary h2 {
  @apply text-2xl font-semibold;
}
.title-primary h3 {
  @apply text-xl font-semibold;
}
.title-primary h4 {
  @apply text-lg font-semibold;
}
.title-primary h5 {
  @apply text-base font-semibold;
}
.title-primary h6 {
  @apply text-sm font-semibold;
}

/* react-data-table-component start */
.rdt_Table {
  @apply !p-0 !border-0 !border-b !border-gray/10 !bg-transparent !rounded-none overflow-hidden;
}
.rdt_Table .rdt_TableCol_Sortable {
  @apply !justify-center text-lg;
}
.rdt_Table .rdt_TableCell {
  @apply !justify-center text-base;
}
.rdt_Table .rdt_TableHeadRow {
  @apply !bg-transparent py-3 !min-h-[unset];
}
.rdt_Table .rdt_TableRow {
  @apply !min-h-[unset] !py-1 odd:!bg-gray/5 even:!bg-white/0;
}
.rdt_Table .cTsUPQ,
.rdt_Pagination {
  @apply !bg-transparent;
}
.rdt_Table .rdt_TableRow:hover {
  @apply bg-primary/0 !border-grey-200 !outline-none;
}
.rdt_Table .rdt_TableCol_Sortable {
  @apply !text-grey-500 text-base;
}

.rdt_Table .rdt_TableRow:not(:last-of-type) {
  @apply !border-b !border-dashed !border-grey-200;
}
.rdt_Table .rdt_TableCol .rdt_TableCol_Sortable {
  @apply !flex flex-wrap !items-center gap-1 !justify-start !text-left;
}
.rdt_Table .rdt_TableCol .rdt_TableCol_Sortable > * {
  @apply text-sm font-semibold text-gray;
}
.rdt_Table .rdt_TableCol .rdt_TableCol_Sortable .brFloq {
  @apply w-[calc(100%_-_22px)];
}
.rdt_Table .rdt_TableCol .rdt_TableCol_Sortable .asc {
  @apply text-primary;
}
.rdt_Table .rdt_TableCol .rdt_TableCol_Sortable .desc {
  @apply text-primary/50;
}
.rdt_Table .rdt_TableRow .rdt_TableCell:first-of-type {
  @apply !justify-start;
}
.rdt_Table .rdt_TableRow .rdt_TableCell {
  @apply !justify-start !flex-wrap !capitalize;
}
.rdt_Table .rdt_TableRow .rdt_TableCell:nth-child(2) {
  @apply !normal-case;
}
.rdt_Table .rdt_TableRow .rdt_TableCell > * {
  @apply text-sm text-opacity-70 text-gray;
}
.rdt_Table .rdt_TableRow .rdt_TableCell a,
.rdt_Table .rdt_TableRow .rdt_TableCell button {
  @apply bg-transparent duration-300 delay-150 ease-linear hover:bg-primary;
}
.rdt_Table .rdt_TableRow .rdt_TableCell a svg,
.rdt_Table .rdt_TableRow .rdt_TableCell button svg {
  @apply text-primary text-sm duration-300 delay-150 ease-linear;
}
.rdt_Table .rdt_TableRow .rdt_TableCell a:hover svg,
.rdt_Table .rdt_TableRow .rdt_TableCell button:hover svg {
  @apply text-white;
}
.rdt_Pagination {
  @apply !border-none;
}
.rdt_Pagination .fimDOL {
  @apply gap-1;
}
.rdt_Pagination .eVrcSZ {
  @apply border border-solid rounded-sm border-gray/10 !outline-none !shadow-none;
}
.rdt_Pagination .DfDUN {
  @apply fill-gray hover:!bg-primary hover:fill-white;
}

/* react-data-table-component end */

/* Login & Signup Start */
.login-root {
  @apply h-screen bg-[linear-gradient(to_right,_#e60a54_50%,_#3b3b3b_50%)] flex items-center justify-center;
}
.login-card-root {
  @apply w-full p-4 mx-4 bg-white border md:w-auto border-primary rounded-xl md:p-8 md:mx-0;
}
.revvit-logo-wrapper {
  @apply flex flex-col items-center justify-center w-full font-medium text-gray;
}
.login-form-logo {
  @apply h-10 mb-1 w-14;
}
.form-title-section {
  @apply flex items-center justify-between w-full mt-4 font-normal text-primary;
}
/* .form-field-wrapper {
    @apply flex flex-col gap-3 mt-4;
} */

.form-textarea {
  @apply w-full text-gray text-sm font-normal border border-gray/10 outline-none rounded-md p-2 focus:border-primary bg-transparent;
}
.form-field-wrapper {
  @apply flex flex-col gap-5;
}

.login-root .form-field {
  @apply md:min-w-[500px] w-full h-12 text-primary text-lg border border-primary outline-none rounded-lg px-4;
}
.login-root .form-field::placeholder {
  @apply text-base font-light;
}

.form-field {
  @apply w-full h-[46px] text-gray text-sm font-normal border border-gray/10 outline-none shadow-none rounded-md px-4 focus:border-primary bg-transparent;
}
.form-field::placeholder {
  @apply text-gray text-opacity-40 text-sm font-normal;
}
.form-field-white {
  @apply w-full h-[46px] text-gray text-sm font-normal border border-gray/10 outline-none rounded-md px-4 focus:border-primary bg-white;
}

.form-field-white::placeholder {
  @apply text-gray text-opacity-40 text-sm font-normal;
}

.btn-wrapper {
  @apply flex items-center justify-between w-full h-full mt-8 text-primary;
}
.login-btn {
  @apply px-8 py-2 text-base font-normal text-white rounded-lg bg-primary md:px-12 md:py-3;
}
.forgot-password-text {
  @apply text-base font-normal cursor-pointer text-primary;
}
.divider-wraper {
  @apply flex items-center my-4 md:my-8;
}
.divider-line {
  @apply w-full border-primary;
}
.divider-content {
  @apply px-4 text-base font-semibold text-primary;
}
.social-media-section {
  @apply flex flex-col items-center;
}
.social-media-title {
  @apply text-lg font-normal text-primary;
}
.social-media-icon-section {
  @apply flex items-center justify-center w-full gap-8 mt-4 md:mt-8;
}
.social-media-icon {
  @apply w-8 h-8 cursor-pointer;
}

/* dashboard header start */
.notifications .dropDown-title {
  @apply border-b border-grey-200;
}
.notifications ul.dropDown-list li:not(:first-child),
.userDropDown .dropDown-list li:not(:first-child) {
  @apply border-t border-dashed border-grey-200;
}
.notifications .dropDown-list::-webkit-scrollbar {
  @apply w-[3px] bg-transparent;
}
.notifications .dropDown-list::-webkit-scrollbar-thumb {
  @apply bg-gray/10;
}
/* dashboard header end */

/* Login & Signup end */
.listing-vehicle .img.landscape {
  @apply relative overflow-hidden pt-[60%];
}

.listing-vehicle .landscape img {
  @apply absolute top-0 left-0 object-cover w-full h-full;
}

.details-single .listing-detail-detail ul {
  @apply flex flex-col items-start justify-start gap-3 p-0 m-0;
}
.details-single .listing-detail-detail ul li {
  @apply flex flex-wrap items-center w-full sm:gap-3;
}
.details-single .listing-detail-detail ul li .text {
  @apply flex flex-wrap items-center justify-start flex-1 gap-2 p-0 m-0 text-sm text-opacity-75 text-gray;
}
.details-single .listing-detail-detail ul li .value {
  @apply flex-1;
}

/* Button */

.btn {
  @apply rounded-md text-sm font-medium py-2 px-6 md:h-[46px] flex flex-wrap items-center justify-center;
}
.btn-gray {
  @apply text-white border bg-gray hover:bg-transparent border-gray hover:text-gray;
}
.btn-primary {
  @apply text-white border bg-primary hover:bg-transparent border-primary hover:text-primary;
}
.btn-primary-border {
  @apply bg-transparent border hover:bg-transparent border-primary text-primary hover:text-white hover:bg-primary;
}

.btn-primary:hover .loader {
  @apply border-primary border-t-transparent;
}
.btn-primary-ico {
  @apply flex items-center justify-center gap-2 bg-primary bg-opacity-10 hover:bg-opacity-100 text-primary hover:text-white;
}
.btn-primary-ico svg {
  @apply text-lg;
}

.listing-detail-title h2 {
  @apply p-0 m-0 text-2xl not-italic font-medium capitalize text-gray lg:text-2xl;
}
.details_overview {
  @apply border-0 border-b-[1px] border-solid border-gray border-opacity-20 pb-10 last:mb-0 last:pb-0 last:border-b-0;
}
.details_overview .listing-detail-detail ul {
  @apply flex flex-col items-start justify-start w-full gap-5 p-0 m-0;
}
.details_overview .listing-detail-detail ul li {
  @apply flex flex-wrap items-center justify-start w-full gap-4 p-0 m-0 sm:flex-row sm:gap-0;
}
.details_overview .listing-detail-detail ul li > * {
  @apply flex-1;
}
.details_overview .listing-detail-detail ul li .text,
.details_overview .listing-detail-detail ul li .value {
  @apply flex flex-wrap items-center justify-start w-auto gap-2 p-0 m-0 text-sm not-italic font-normal capitalize text-gray;
}

.details_overview .listing-detail-detail ul li .text svg {
  @apply text-lg;
}
/* Details page End */

.fancybox__container {
  @apply z-[999999];
}
.grid-img_block.img {
  @apply first:col-span-2 first:row-span-3 relative overflow-hidden block w-full max-md:pt-[calc(4/5*100%)] md:pt-[calc(9/16*100%)];
}
.grid-img_block.img img {
  @apply absolute top-0 left-0 object-cover w-full h-full;
}
.grid-img_block.img:first-child img {
  @apply object-cover w-full h-full;
}

.subHeading-5 {
  @apply text-base;
}

.txt_area p,
.txt_area ol,
.ck.ck-editor__editable_inline p,
.ck.ck-editor__editable_inline ol {
  @apply mb-5 last:mb-0 text-sm text-gray;
}
.txt_area ol,
.ck.ck-editor__editable_inline ol {
  @apply px-5;
}
.txt_area ol li,
.ck.ck-editor__editable_inline ol li {
  @apply mb-2 last:mb-0 list-decimal;
}
.select_re > div,
.select_re > div > div,
.select_re > div > div > div {
  @apply min-h-0 border-none py-0;
}
.select_re > div > div > span {
  @apply my-0;
}

/* ckeditor height increase */
.ck-editor__editable {
  min-height: 200px !important;
  max-height: 300px !important;
}

.btn-primary-a {
  @apply w-auto py-1 px-3 !bg-primary border border-transparent text-white text-sm font-medium rounded-full duration-300 delay-150 ease-linear hover:text-primary hover:!bg-transparent hover:border-primary;
}

.flex-center {
  @apply flex items-center justify-center;
}
.custom-radio {
  @apply flex-center text-sm border-[1px] border-gray border-opacity-10 cursor-pointer rounded-md px-2 py-3 min-w-[50px];
}

input:checked + .custom-radio {
  @apply border-primary/0;
}

.custom-radio-new {
  @apply rounded-md 
    text-sm 
    font-normal 
    bg-primary/10 
    border-[1px] 
    border-primary 
    border-opacity-10 
    py-3 
    px-5 
    outline-none 
    transition 
    focus:border-primary 
    active:border-primary 
    disabled:cursor-default disabled:bg-white flex-center duration-300 delay-150 ease-linear;
}
.custom-radio-new:hover {
  @apply border-primary bg-primary text-white;
}
input:checked + .custom-radio-new {
  @apply border-primary bg-primary text-white;
}

.heading-1 {
  @apply text-2xl lg:text-4xl font-bold capitalize;
}
.heading-2 {
  @apply text-xl lg:text-3xl font-bold capitalize;
}
.heading-3 {
  @apply text-lg lg:text-2xl font-bold capitalize;
}
.heading-4 {
  @apply text-base lg:text-xl font-bold capitalize;
}
.heading-5 {
  @apply text-sm lg:text-lg font-bold;
}
.heading-6 {
  @apply text-sm font-bold capitalize;
}

.form-field-white {
  @apply w-full h-[46px] text-gray text-sm font-normal border border-gray/10 outline-none rounded-md px-4 focus:border-primary bg-white;
}

.form-field-white::placeholder {
  @apply text-gray text-opacity-40 text-sm font-normal;
}
.modal-content {
  @apply flex flex-col items-center justify-center m-0 p-5 sm:p-8 gap-8;
}

.btn-primary-link {
  @apply text-primary font-medium hover:underline;
}

/* seller-listing-view start */

.listing-vehicle .landscape img {
  @apply absolute top-0 left-0 w-full h-full object-cover;
}
.details-view-single .listing-detail-detail ul {
  @apply flex flex-col items-start justify-start m-0 p-0 gap-3;
}
.details-view-single .listing-detail-detail ul li {
  @apply flex flex-wrap items-center w-full sm:gap-3;
}
.details-view-single .listing-detail-detail ul li .text {
  @apply flex flex-wrap items-center justify-start m-0 p-0 gap-2 flex-1 text-gray text-sm text-opacity-75;
}
.details-view-single .listing-detail-detail ul li .value {
  @apply flex-1;
}
.tabs {
  @apply flex flex-wrap items-center gap-4;
}
.tabs.active {
  @apply text-primary;
}
.add-tabs .tabs.active {
  @apply bg-primary text-white rounded-tl-lg rounded-tr-lg;
}
.tabs.active span {
  @apply bg-primary border-primary text-white;
}

.live-car_list .img-grid {
  @apply sm:grid-cols-2;
}
/* seller-listing-view end */
.subHeading-6 {
  @apply text-xs;
}
.title-white {
  @apply text-white;
}

/* VIN Details Start */
.next-prev-button svg {
  @apply text-2xl;
}
.prev {
  @apply flex items-center w-auto text-gray bg-transparent border-none hover:bg-transparent hover:text-primary;
}
.prev svg {
  @apply rotate-180 text-3xl;
}
/* VIN Details End */

.text-70 {
  @apply text-opacity-70;
}
.text-60 {
  @apply text-opacity-60;
}
.text-50 {
  @apply text-opacity-50;
}
.text-40 {
  @apply text-opacity-40;
}
.text-30 {
  @apply text-opacity-30;
}
.text-20 {
  @apply text-opacity-20;
}
.text-10 {
  @apply text-opacity-10;
}

.value > span[aria-label] {
  @apply w-7 h-7 rounded-full bg-black/10 block p-[2px] overflow-hidden border border-gray/10;
}

.value span[aria-label='#000000'] span {
  @apply w-full h-full bg-black block rounded-full;
}

.value span[aria-label='#FFFFFF'] span {
  @apply w-full h-full bg-white block rounded-full;
}

.value span[aria-label='#C0C0C0'] span {
  @apply w-full h-full bg-[#C0C0C0] block rounded-full;
}

.value span[aria-label='#808080'] span {
  @apply w-full h-full bg-[#808080] block rounded-full;
}

.value span[aria-label='#0000FF'] span {
  @apply w-full h-full bg-[#0000FF] block rounded-full;
}

.value span[aria-label='#FF0000'] span {
  @apply w-full h-full bg-[#FF0000] block rounded-full;
}

.value span[aria-label='#FFFF00'] span {
  @apply w-full h-full bg-[#FFFF00] block rounded-full;
}

.value span[aria-label='#008000'] span {
  @apply w-full h-full bg-[#008000] block rounded-full;
}

.value span[aria-label='#800080'] span {
  @apply w-full h-full bg-[#800080] block rounded-full;
}

.value span[aria-label='#FFA500'] span {
  @apply w-full h-full bg-[#FFA500] block rounded-full;
}

.value span[aria-label='#F5F5DC'] span {
  @apply w-full h-full bg-[#F5F5DC] block rounded-full;
}

.value span[aria-label='#A52A2A'] span {
  @apply w-full h-full bg-[#A52A2A] block rounded-full;
}

.value span[aria-label='#CD7F32'] span {
  @apply w-full h-full bg-[#CD7F32] block rounded-full;
}

.value span[aria-label='#FFD700'] span {
  @apply w-full h-full bg-[#FFD700] block rounded-full;
}

.value span[aria-label='#FFC0CB'] span {
  @apply w-full h-full bg-[#FFC0CB] block rounded-full;
}

.value span[aria-label='#B87333'] span {
  @apply w-full h-full bg-[#B87333] block rounded-full;
}

.value span[aria-label='#FF00FF'] span {
  @apply w-full h-full bg-[#FF00FF] block rounded-full;
}

.value span[aria-label='#40E0D0'] span {
  @apply w-full h-full bg-[#40E0D0] block rounded-full;
}

.value span[aria-label='#00FF00'] span {
  @apply w-full h-full bg-[#00FF00] block rounded-full;
}

.value span[aria-label='Black'] span,
.list-unstyled span[aria-label='Black'] span {
  @apply block w-full h-full bg-black rounded-full;
}

.value span[aria-label='White'] span,
.list-unstyled span[aria-label='White'] span {
  @apply block w-full h-full bg-white rounded-full;
}

.value span[aria-label='Silver'] span,
.list-unstyled span[aria-label='Silver'] span {
  @apply w-full h-full bg-[#C0C0C0] block rounded-full;
}

.value span[aria-label='Gray'] span,
.list-unstyled span[aria-label='Gray'] span {
  @apply w-full h-full bg-[#808080] block rounded-full;
}

.value span[aria-label='Blue'] span,
.list-unstyled span[aria-label='Blue'] span {
  @apply w-full h-full bg-[#0000FF] block rounded-full;
}

.value span[aria-label='Red'] span,
.list-unstyled span[aria-label='Red'] span {
  @apply w-full h-full bg-[#FF0000] block rounded-full;
}

.value span[aria-label='Yellow'] span,
.list-unstyled span[aria-label='Yellow'] span {
  @apply w-full h-full bg-[#FFFF00] block rounded-full;
}

.value span[aria-label='Green'] span,
.list-unstyled span[aria-label='Green'] span {
  @apply w-full h-full bg-[#008000] block rounded-full;
}

.value span[aria-label='Purple'] span,
.list-unstyled span[aria-label='#800080'] span {
  @apply w-full h-full bg-[#800080] block rounded-full;
}

.value span[aria-label='Orange'] span,
.list-unstyled span[aria-label='Orange'] span {
  @apply w-full h-full bg-[#FFA500] block rounded-full;
}

.value span[aria-label='Beige'] span,
.list-unstyled span[aria-label='Beige'] span {
  @apply w-full h-full bg-[#F5F5DC] block rounded-full;
}

.value span[aria-label='Brown'] span,
.list-unstyled span[aria-label='Brown'] span {
  @apply w-full h-full bg-[#A52A2A] block rounded-full;
}

.value span[aria-label='Bronze'] span,
.list-unstyled span[aria-label='Bronze'] span {
  @apply w-full h-full bg-[#CD7F32] block rounded-full;
}

.value span[aria-label='Gold'] span,
.list-unstyled span[aria-label='Gold'] span {
  @apply w-full h-full bg-[#FFD700] block rounded-full;
}

.value span[aria-label='Pink'] span,
.list-unstyled span[aria-label='Pink'] span {
  @apply w-full h-full bg-[#FFC0CB] block rounded-full;
}

.value span[aria-label='Copper'] span,
.list-unstyled span[aria-label='Copper'] span {
  @apply w-full h-full bg-[#B87333] block rounded-full;
}

.value span[aria-label='Magenta'] span,
.list-unstyled span[aria-label='Magenta'] span {
  @apply w-full h-full bg-[#FF00FF] block rounded-full;
}

.value span[aria-label='Turquoise'] span,
.list-unstyled span[aria-label='Turquoise'] span {
  @apply w-full h-full bg-[#40E0D0] block rounded-full;
}

.value span[aria-label='Lime'] span,
.list-unstyled span[aria-label='Lime'] span {
  @apply w-full h-full bg-[#00FF00] block rounded-full;
}

.btn-gray-link {
  @apply font-medium text-gray hover:underline;
}

.btn-full {
  @apply w-full;
}

.subHeading-3 {
  @apply text-xl;
}
